body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  background-color: #121212; /* Eğer dark mode isteniyorsa */
  color: #e0e0e0; /* Metinlerin genel rengi */
}

/* Kod blokları için daha iyi görünüm */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: #1E1E1E; /* Koyu arka plan */
  color: #BB86FC; /* Mor kod rengi */
  padding: 5px;
  border-radius: 3px;
}
