/* src/Carousel.css */
.carousel {
  position: relative;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Daha belirgin gölge */
}

.slide {
  display: none;
  opacity: 0;
  transform: scale(0.95); /* Başlangıçta küçültülmüş */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.slide.active {
  display: block;
  opacity: 1;
  transform: scale(1); /* Slaytlar aktif olduğunda büyütülüyor */
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2); /* Daha az belirgin */
  border: 2px solid rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease, border 0.3s ease;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: rgba(255, 255, 255, 0.6); /* Hover olduğunda daha belirgin */
  border-color: rgba(255, 255, 255, 1);
  
}

.left-arrow {
  left: 15px;
  
}

.right-arrow {
  right: 15px;
}

.left-arrow svg,
.right-arrow svg {
  color: #fff; /* Beyaz oklar */
  width: 20px;
  height: 20px;
}

.dots {
  text-align: center;
  padding: 15px;
  margin-top: 20px;
  
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5); /* Daha az belirgin */
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.4s ease, transform 0.4s ease, box-shadow 0.4s;
}

.dot.active {
  background-color: #fffbf9; /* Aktif durum için turuncu */
  transform: scale(1.2);
  box-shadow: 0 0 10px rgba(255, 107, 53, 0.5); /* Hafif parlama efekti */
}

.dot:hover {
  background-color: #ff6b35;
  transform: scale(1.2);
}
