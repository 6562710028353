/* Mobil Ekranlar İçin Responsive Tasarım */
@media screen and (max-width: 400px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio {
    padding: 20px;
    width: 100%; /* Ekrandan taşmaması için %100 genişlik */
  }

  .glass {
    flex-direction: column; /* Mobilde üst üste gelecek şekilde düzenleme */
    align-items: center;
  }

  .glass .image-container {
    margin-bottom: 20px; /* Resim ile içerik arasına boşluk bırakma */
  }

  .glass .content-container {
    text-align: center; /* Mobilde ortalanmış metin */
  }
}

/* Temel Ayarlar */
body {
  background-color: #121212; /* Koyu gri arka plan */
  color: #e0e0e0; /* Hafif gri yazı rengi */
  font-family: "Roboto", sans-serif; /* Modern ve okunaklı bir font */
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

/* Link Renk ve Hover Durumları */
a {
  color: #bb86fc; /* Daha yumuşak bir mor tonu */
  text-decoration: none;
}

a:hover {
  color: #e0e0e0; /* Hover'da daha açık renk */
}

/* Bileşenler için Genel Ayarlar */
#features,
#about,
#services,
#testimonials,
#team,
#contact,
#footer {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Cam efekti için şeffaf arka plan */
  backdrop-filter: blur(10px); /* Arka planı bulanıklaştırma */
  color: #e0e0e0; /* Hafif gri yazı rengi */
  padding: 20px;
  border-radius: 10px; /* Köşeleri yumuşatma */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Hafif kenarlık */
  margin-bottom: 20px; /* Bileşenler arasına boşluk */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.glass .image-container {
  flex: 1;
  margin-right: 20px;
}

.glass .image-container img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.glass .content-container {
  flex: 1;
  padding: 20px;
}

/* Feature Öğeleri için */
.feature-item {
  background-color: #292929; /* Biraz daha açık gri ton */
  color: #e0e0e0;
  padding: 15px;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out; /* Hover'da büyütme efekti */
}

.feature-item:hover {
  transform: scale(1.05); /* Hover durumunda büyütme */
}

/* Butonlar */
button {
  background-color: #bb86fc; /* Dark mode'da vurgu için mor */
  color: #121212; /* Buton yazı rengi */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease; /* Geçişler */
}



/* Header Ayarları */
#header {
  background-color: #181818; /* Çok koyu gri arka plan */
  color: #e0e0e0; /* Yazı rengi */
  text-align: center;
}

/* Navigasyon */
nav {
  background-color: #181818; /* Koyu arka plan */
  color: #e0e0e0;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

nav a {
  color: #bb86fc;
  padding: 15px 20px; /* Tıklanabilir alanı genişletme */
  transition: color 0.2s ease; /* Renk geçişi */
}

nav a:hover {
  color: #e0e0e0; /* Hover durumunda daha açık renk */
}

/* Genel Geçişler */
* {
  transition: background-color 0.3s ease, color 0.3s ease; /* Tüm elemanlar için geçiş */
}

/* CSS Reset - Tarayıcı varsayılanlarını sıfırlama */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Box modelin tüm öğelerde aynı olması için */
}

/* Footer Section */
#footer {
  background-color: rgba(30, 30, 30, 0.8); /* Footer için cam efekti */
  color: #888;
  padding: 30px 0;
  text-align: center;
}

#footer a {
  color: #608dfd;
}

#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

#background-video {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
